@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;500;600&display=swap");


body{
  font-family: 'Poppins', sans-serif;
  background-color: black;
  min-height: 100vh;
  /* border: 1px solid white */
  background-image: url('./static/bg.webp');
}


.login-input:focus{
  outline: none;
  border: 2px solid rgb(53, 156, 211);
}

.login-input{
  border: none;
  width:100%;
  box-sizing: border-box;
  height: 50px;
  padding-left:10px;
  font-family:'Poppins';
  border-radius: 5px;
}


.centered{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.sponsor-img-div{
  width: 100%;
  height: 100%
}

.sponsor-img{
  width: 100%;
}


li{
  margin-top: 10px;
}